import { defineMessages } from "react-intl";

export const messages = defineMessages( {
	paymentDetailsTitle: {
		id: "subscriptionPage.paymentDetails.title",
		defaultMessage: "Payment details",
	},
	startDate: {
		id: "subscriptionPage.paymentDetails.startDate",
		defaultMessage: "Start date",
	},
	billing: {
		id: "subscriptionPage.paymentDetails.billing",
		defaultMessage: "Billing",
	},
	nextBilling: {
		id: "subscriptionPage.paymentDetails.nextBilling",
		defaultMessage: "Next billing",
	},
	endsOn: {
		id: "subscriptionPage.paymentDetails.endsOn",
		defaultMessage: "Ends on",
	},
	paymentMethod: {
		id: "subscriptionPage.paymentDetails.paymentMethod",
		defaultMessage: "Payment method",
	},
	invoicesTitle: {
		id: "subscriptionPage.invoices.title",
		defaultMessage: "Invoices",
	},
	includedSectionTitle: {
		id: "subscriptionPage.included.title",
		defaultMessage: "Included in this subscription",
	},
	downloadLinkText: {
		id: "subscriptionPage.download.linkText",
		defaultMessage: "Download",
	},
	installLinkText: {
		id: "subscriptionPage.install.linkText",
		defaultMessage: "Install",
	},
	installationGuides: {
		id: "subscriptionPage.installationGuides",
		defaultMessage: "Read our installation guides",
	},
	installationGuide: {
		id: "subscriptionPage.installationGuide",
		defaultMessage: "Read our installation guide",
	},
	yoastCoursesTitle: {
		id: "subscriptionPage.yoastCoursesTitle",
		defaultMessage: "Yoast SEO Academy courses",
	},
	yoastCoursesDescription: {
		id: "subscriptionPage.yoastCoursesDescription",
		defaultMessage: "access only for this MyYoast account",
	},
	yoastPluginsTitle: {
		id: "subscriptionPage.yoastPluginsTitle",
		defaultMessage: "Yoast SEO plugins",
	},
	yoastPluginsDescription: {
		id: "subscriptionPage.yoastPluginsDescription",
		defaultMessage: "{ used } / { limit } connected sites",
	},
	coursesTitle: {
		id: "subscriptionPage.includedPlugins.courseTitle",
		defaultMessage: "Courses",
	},
	productsTitle: {
		id: "subscriptionPage.includedPlugins.productsTitle",
		defaultMessage: "Products",
	},
	connectedSitesTitle: {
		id: "subscriptionPage.includedPlugins.connectedSites.title",
		defaultMessage: "Connected sites ({ used } / { limit })",
	},
	connectSite: {
		id: "subscriptionPage.includedPlugins.connectSite",
		defaultMessage: "Add site",
	},
	manageSite: {
		id: "subscriptionPage.includedPlugins.manageSite",
		defaultMessage: "Manage",
	},
	linkAccountsTitle: {
		id: "subscriptionPage.connectedEmailAccess.linkedAccountsTitle",
		defaultMessage: "Linked Google accounts ({ used } / { limit })",
	},
	linkAccount: {
		id: "subscriptionPage.connectedEmailAccess.linkAccount",
		defaultMessage: "Link account",
	},
	pendingVerification: {
		id: "subscriptionPage.connectedEmailAccess.pendingVerification",
		defaultMessage: "Verification is pending…",
	},
	unlinkAccount: {
		id: "subscriptionPage.connectedEmailAccess.unlinkAccount",
		defaultMessage: "Unlink account",
	},
	docsAddonsTitle: {
		id: "subscriptionPage.docsAddonsTitle",
		defaultMessage: "Yoast SEO Google Docs add-on",
	},
	docsAddonsDescription: {
		id: "subscriptionPage.docsAddonsDescription",
		defaultMessage: "{used} / {limit} linked Google accounts",
	},
	linkGoogleAccountTitle: {
		id: "subscriptionPage.linkGoogleAccount.title",
		defaultMessage: "Link Google account",
	},
	linkGoogleAccountInstruction: {
		id: "subscriptionPage.linkGoogleAccount.instruction",
		defaultMessage: "Please enter the email address of the Google account you want to use the add-on with.",
	},
	linkGoogleAccountEmailLabel: {
		id: "subscriptionPage.linkGoogleAccount.emailLabel",
		defaultMessage: "Google account email address",
	},
	linkGoogleAccountEmailValidationError: {
		id: "subscriptionPage.linkGoogleAccount.emailValidationError",
		defaultMessage: "Please enter a valid email address.",
	},
	linkGoogleAccountEmailClarification: {
		id: "subscriptionPage.linkGoogleAccount.emailClarification",
		defaultMessage: "We’ll send a verification email to this address to confirm your Google account.",
	},
	linkGoogleAccountEmailAlreadyLinked: {
		id: "subscriptionPage.linkGoogleAccount.emailAlreadyLinked",
		defaultMessage: "This email address is already linked to this subscription.",
	},
	linkGoogleAccountNoMoreSeats: {
		id: "subscriptionPage.linkGoogleAccount.emailNoMoreSeats",
		// eslint-disable-next-line max-len
		defaultMessage: "You have reached the maximum number of linked Google accounts for this subscription. Please unlink an account before linking a new one.",
	},
	linkGoogleAccountGenericError: {
		id: "subscriptionPage.linkGoogleAccount.genericError",
		defaultMessage: "An error occurred while linking the Google account. Please try again later.",
	},
	linkGoogleAccountSubmit: {
		id: "subscriptionPage.linkGoogleAccount.submit",
		defaultMessage: "Link Google account",
	},
	linkGoogleAccountCancel: {
		id: "subscriptionPage.linkGoogleAccount.cancel",
		defaultMessage: "Cancel",
	},
	linkGoogleAccountInviteSentTitle: {
		id: "subscriptionPage.linkGoogleAccount.inviteSentTitle",
		defaultMessage: "Email has been sent",
	},
	linkGoogleAccountInviteSentDescription: {
		id: "subscriptionPage.linkGoogleAccount.inviteSentDescription",
		defaultMessage: "A verification email has been sent to ",
	},
	linkGoogleAccountGotIt: {
		id: "subscriptionPage.linkGoogleAccount.gotIt",
		defaultMessage: "Got it!",
	},
	unlinkAccountConfirmation: {
		id: "subscriptionPage.connectedEmailAccess.unlinkAccountConfirmation",
		defaultMessage: "Are you sure you want to unlink this account? {email} will lose access to the Yoast SEO Google Docs add-on.",
	},
	unlinkAccountFailed: {
		id: "subscriptionPage.connectedEmailAccess.unlinkAccountFailed",
		defaultMessage: "An error occurred while unlinking the account. Please try again later.",
	},
	noConnectedSites: {
		id: "noConnectedSites.message",
		defaultMessage: "There are no connected sites to this subscription.",
	},
	title: {
		id: "subscription.title",
		defaultMessage: "",
	},
	subtitle: {
		id: "subscription.subtitle",
		defaultMessage: "",
	},
	subscriptionDetailsTitle: {
		id: "subscription.detailsTitle",
		defaultMessage: "Subscription details",
	},
	subscriptionNumber: {
		id: "subscription.number",
		defaultMessage: "Number",
	},
	subscriptionStatus: {
		id: "subscription.status",
		defaultMessage: "Status",
	},
	subscriptionActionTitle: {
		id: "subscription.actionTitle",
		defaultMessage: "Subscription actions",
	},
	orderDate: {
		id: "order.date",
		defaultMessage: "Date",
	},
	orderId: {
		id: "order.id",
		defaultMessage: "Order",
	},
	orderItems: {
		id: "order.items",
		defaultMessage: "Items",
	},
	orderTotal: {
		id: "order.total",
		defaultMessage: "Total",
	},
	orderStatus: {
		id: "order.status",
		defaultMessage: "Status",
	},
	invoiceButton: {
		id: "subscriptionDetails.buttons.invoice",
		defaultMessage: "Invoice",
	},
	cancelLink: {
		id: "subscriptionDetails.buttons.cancel",
		defaultMessage: "Cancel subscription",
	},
	cancelPending: {
		id: "subscriptionDetails.cancelPending",
		defaultMessage: "Your subscription has been cancelled.",
	},
	extendSubscriptionLabel: {
		id: "subscriptionDetails.extendSubscriptionLabel",
		defaultMessage: "Renew subscription",
	},
	switchPayentMethodLabel: {
		id: "subscriptionDetails.extendSubscriptionLabel",
		defaultMessage: "Use another payment method for your next renewal",
	},
	extendSubscription: {
		id: "subscriptionDetails.extendSubscription",
		defaultMessage: "Renew subscription",
	},
	switchPaymentMethod: {
		id: "subscriptionDetails.label.switchPaymentMethod",
		defaultMessage: "Switch payment method",
	},
	addSiteToSubscription: {
		id: "subscriptionDetails.label.addSite",
		defaultMessage: "Add site",
	},
	cannotAddSiteToSubscription: {
		id: "subscriptionDetails.cant.addSite",
		defaultMessage: "You cannot add a site to a subscription that lasts more than five years",
	},
	addSiteToSubscriptionLink: {
		id: "subscriptionDetails.buttons.addSiteLink",
		defaultMessage: "Add site to this subscription",
	},
	upgradeToBundleLabel: {
		id: "subscriptionDetails.label.upgradeToBundle",
		defaultMessage: "Upgrade to bundle",
	},
	upgradeToBundleLink: {
		id: "subscriptionDetails.link.upgradeToBundle",
		defaultMessage: "Upgrade subscription to bundle",
	},
	switchToAutomaticLabel: {
		id: "subscriptionDetails.switchToAutomaticLabel",
		defaultMessage: "Automatic renewal",
	},
	switchToAutomatic: {
		id: "subscriptionDetails.switchToAutomatic",
		defaultMessage: "Enable automatic payments for future renewals",
	},
} );
