import { ArrowTopRightOnSquareIcon } from "@heroicons/react/20/solid";
import React from "react";
import { Link } from "@yoast/ui-library";
import PropTypes from "prop-types";
import * as styles from "./styles.scss";
import classnames from "classnames";

/**
 * A component that renders an external link with an icon and opens in a new tab.
 * @param {Object} props The component props. Share the ui-library Link props.
 * @param {ReactNode} children The contents of the link.
 * @returns {Element} The link.
 */
export const ExternalLink = ( { children, ...props } ) => {
	return <Link { ...props } target="_blank" className={ classnames( props.classNames, styles.externalLink ) }>
		{ children }
		<ArrowTopRightOnSquareIcon className={ styles.externalLinkIcon } />
	</Link>;
};

ExternalLink.propTypes = {
	children: PropTypes.node.isRequired,
	href: PropTypes.string.isRequired,
	classNames: PropTypes.string,
};
