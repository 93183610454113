import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { Button, Modal, TextField } from "@yoast/ui-library";
import { FormattedMessage, injectIntl, intlShape } from "react-intl";
import { messages } from "../messages";
import * as styles from "./styles.scss";
import PropTypes from "prop-types";
import { debounce } from "lodash/function";
import { doRequest, prepareInternalRequest } from "shared-frontend/functions/api";
import ErrorDisplay from "../../../errors/ErrorDisplay";
import { CheckIcon } from "@heroicons/react/20/solid";
import { SubscriptionDetailContext } from "../../../context/SubscriptionDetailContext";

/** @typedef {import("../../../../../nestjs-server/src/domains/Licensing/UserInterface/dtos/InviteDto").InviteResponseDto} */

export const LinkGoogleAccountModal = injectIntl( ( { intl, isOpen = false, onClose } ) => {
	const { subscription, request: subscriptionRequest }  = useContext( SubscriptionDetailContext );
	const [ email, setEmail ]                             = useState( "" );
	const [ inputIsValid, setInputIsValid ]               = useState( false );
	const [ showValidationError, setShowValidationError ] = useState( false );
	const [ isLoading, setIsLoading ]                     = useState( false );
	const [ error, setError ]                             = useState( null );
	const [ success, setSuccess ]                         = useState( false );
	const formRef                                         = useRef( null );

	const validationError = {
		variant: "error",
		message: intl.formatMessage( messages.linkGoogleAccountEmailValidationError ),
	};
	useEffect( () => {
		const debouncedValidation = debounce( () => {
			const isValid = formRef.current?.checkValidity();
			setInputIsValid( isValid );
			setShowValidationError( isValid === false && email !== "" );
		}, 500 );
		debouncedValidation();
		return debouncedValidation.cancel;
	}, [ email ] );

	useEffect( () => {
		if ( ! isOpen ) {
			setEmail( "" );
			setInputIsValid( false );
			setShowValidationError( false );
			setIsLoading( false );
			setError( null );
			setSuccess( false );
		}
	}, [ isOpen ] );


	const handleEmailChange = useCallback( ( event ) => {
		setEmail( event.target.value );
	}, [ setEmail ] );

	const handleSubmit = useCallback( async( event ) => {
		event.preventDefault();

		const isValid = formRef.current.checkValidity();
		if ( ! isValid ) {
			setShowValidationError( true );
			return;
		}

		setIsLoading( true );
		setError( null );

		try {
			/** @type {InviteResponseDto} */
			const response = await doRequest( prepareInternalRequest(
				`v1/access/${ subscription.id }/invite`,
				"POST",
				{ email },
			) );
			if ( response.success ) {
				setSuccess( true );
				subscriptionRequest.reload();
			} else {
				switch ( response.errorCode || "" ) {
					case "ALREADY_GRANTED":
						setError( {
							type: "warning",
							message: intl.formatMessage( messages.linkGoogleAccountEmailAlreadyLinked ),
						} );
						break;
					case "NO_SEATS_AVAILABLE":
						setError( {
							type: "warning",
							message: intl.formatMessage( messages.linkGoogleAccountNoMoreSeats ),
						} );
						break;
					default:
						setError( {
							type: "error",
							message: intl.formatMessage( messages.linkGoogleAccountGenericError ),
						} );
						break;
				}
			}
		} catch ( e ) {
			setError( e.message.toString() );
		} finally {
			setIsLoading( false );
		}
	}, [ formRef, email ] );

	if ( ! subscription ) {
		return <></>;
	}

	if ( success ) {
		return <SuccessModal email={ email } isOpen={ isOpen } onClose={ onClose } />;
	}

	return <Modal isOpen={ isOpen } onClose={ onClose }>
		<Modal.Panel>
			<Modal.Title size={ "2" } className={ styles.title }>
				<FormattedMessage { ...messages.linkGoogleAccountTitle } />
			</Modal.Title>
			<Modal.Description><FormattedMessage { ...messages.linkGoogleAccountInstruction } /></Modal.Description>
			<form className={ styles.form } ref={ formRef } onSubmit={ handleSubmit }>
				<TextField
					id="email"
					type="email"
					required={ true }
					label={ intl.formatMessage( messages.linkGoogleAccountEmailLabel ) }
					description={ intl.formatMessage( messages.linkGoogleAccountEmailClarification ) }
					value={ email }
					onChange={ handleEmailChange }
					validation={ showValidationError ? validationError : {} }
				/>
				<ErrorDisplay
					error={ error }
					className={ styles.error }
				/>
				<div className={ styles.actions }>
					<Button variant="secondary" onClick={ onClose }>
						<FormattedMessage { ...messages.linkGoogleAccountCancel } />
					</Button>
					<Button isLoading={ isLoading } type="submit" disabled={ ! inputIsValid }>
						<FormattedMessage { ...messages.linkGoogleAccountSubmit } />
					</Button>
				</div>
			</form>
		</Modal.Panel>
	</Modal>;
} );

LinkGoogleAccountModal.propTypes = {
	isOpen: PropTypes.bool,
	onClose: PropTypes.func.isRequired,
	intl: intlShape,
};


const SuccessModal = injectIntl( ( { email, isOpen, onClose } ) => {
	const gotItButton = useRef( null );
	return <Modal isOpen={ isOpen } onClose={ onClose } initialFocus={ gotItButton } className={ styles.successModal }>
		<Modal.Panel className={ styles.successPanel }>
			<CheckIcon className={ styles.check } />
			<Modal.Title size={ "2" } className={ styles.title }>
				<FormattedMessage { ...messages.linkGoogleAccountInviteSentTitle } />
			</Modal.Title>
			<Modal.Description>
				<FormattedMessage { ...messages.linkGoogleAccountInviteSentDescription } />
				<span className={ styles.email }>{ email }</span>.
			</Modal.Description>
			<Button onClick={ onClose } ref={ gotItButton }><FormattedMessage { ...messages.linkGoogleAccountGotIt } /></Button>
		</Modal.Panel>
	</Modal>;
} );
