/* External dependencies */
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import { defineMessages, FormattedMessage, injectIntl, intlShape } from "react-intl";
import validate from "validate.js";
import { speak } from "@wordpress/a11y";
import _debounce from "lodash/debounce";
/* Internal dependencies */
import { Button, SelectField, TextField } from "@yoast/ui-library";
import * as styles from "./AddSiteStyles.scss";
import { linkSite, updateSiteUrl } from "shared-frontend/redux/actions/sites";
import { connect } from "react-redux";
import { noop } from "shared-frontend/functions/noop";
import { isString } from "lodash/lang";
import { ErrorPropTypeShape } from "../errors/ErrorDisplay";

const messages = defineMessages( {
	validationFormatURL: {
		id: "validation.format.url",
		defaultMessage: "Please enter a valid URL. Remember to use http:// or https://.",
	},
	validationInvalidCharactersURL: {
		id: "validation.invalid.characters.url",
		defaultMessage: "Please do not enter your credentials in the URL.",
	},
	site: {
		id: "sites.addSite.enterUrl",
		defaultMessage: "Please enter the URL of the site you would like to add to your account.",
	},
	platform: {
		id: "sites.addSite.selectPlatform",
		defaultMessage: "Please select the platform that your website is running on.",
	},
} );

/**
 * A form for adding a site to the user's account.
 *
 * @param {Object} props The props required.
 * @param {string} [props.initialURL] The initial URL to show in the input field.
 * @param {Object} props.intl The intl object.
 * @param {Function} props.onConnect A function to call when the form is submitted.
 * @param {Function} props.onChange A function to call when the URL input changes.
 * @param {object} [props.connectionError] Error that occurred during the connection process.
 * @returns {Element} The AddSite form.
 */
export const AddSite = injectIntl( ( { initialURL = "", intl, onConnect, onChange = noop, connectionError } ) => {
	const [ selectedURL, setSelectedURL ]           = useState( initialURL );
	const [ selectedPlatform, setSelectedPlatform ] = useState( "wordpress" );
	const [ validationError, setValidationError ]   = useState( null );
	const [ lastValidatedURL, setLastValidatedURL ] = useState( "" );
	const canSubmit                                 = selectedURL.length > 0 && lastValidatedURL === selectedURL && validationError === null;

	const validateURLInput = useCallback( _debounce(
		( input ) => {
			const validationErrors = validate(
				{ URL: input },
				{
					URL: {
						url: {
							// Only allow http and https.
							schemes: [ "http", "https" ],
							// Allow local URLs.
							allowLocal: true,
							message: intl.formatMessage( messages.validationFormatURL ),
						},
						disallowCredentials: {
							message: intl.formatMessage( messages.validationInvalidCharactersURL ),
						},
					},
				},
				{ format: "detailed" },
			);
			if ( validationErrors?.length ) {
				setValidationError( validationErrors[ 0 ].options.message );
			} else {
				setValidationError( null );
			}
			setLastValidatedURL( input );
		}, 500 ), [ setValidationError, setLastValidatedURL ] );

	useEffect( () => {
		validate.validators.disallowCredentials = ( value, options ) => {
			try {
				const url = new URL( value );

				if ( url.username || url.password ) {
					return options.message;
				}
			} catch ( error ) {
				return null;
			}
		};
	}, [] );

	useEffect( () => {
		if ( isString( validationError ) ) {
			speak( validationError, "assertive" );
		}
	}, [ validationError ] );

	useEffect( () => {
		if ( selectedURL.length === 0 ) {
			return;
		}
		validateURLInput( selectedURL );
	}, [ selectedURL, setValidationError ] );

	const handleURLChange = useCallback( ( event ) => {
		setSelectedURL( event.target.value );
		onChange( event.target.value );
	}, [ setSelectedURL ] );


	const submit = useCallback( ( event ) => {
		event.preventDefault();
		if ( canSubmit ) {
			return;
		}
		onConnect( selectedURL, selectedPlatform );
	}, [ onConnect, selectedURL, selectedPlatform ] );

	return (
		<div id="add-site-modal">
			<form onSubmit={ submit } className={ styles.form }>
				<TextField
					type="url"
					id="add-site-input"
					label="Site URL"
					description={ intl.formatMessage( messages.site ) }
					placeholder="https://example-site.com"
					value={ selectedURL }
					onChange={ handleURLChange }
					validation={ {
						variant: "error",
						message: validationError || connectionError?.message,
					} }
				/>
				<SelectField
					id="add-site-select-platform"
					name="selectPlatform"
					value={ selectedPlatform }
					label="Platform"
					description={ intl.formatMessage( messages.platform ) }
					onChange={ setSelectedPlatform }
					options={ [
						{ value: "wordpress", label: "WordPress" },
						{ value: "typo3", label: "TYPO3" },
					] }
				/>
				<div>
					<Button
						type="submit"
						disabled={ ! canSubmit }
						aria-disabled={ ! canSubmit }
					>
						<FormattedMessage id="sites.addSite.connect" defaultMessage="Add site" />
					</Button>
				</div>
			</form>
		</div>
	);
} );

AddSite.propTypes = {
	intl: intlShape.isRequired,
	onConnect: PropTypes.func.isRequired,
	initialURL: PropTypes.string,
	onChange: PropTypes.func,
	connectionError: ErrorPropTypeShape,
};


const mapStateToProps = ( state ) => {
	return {
		connectionError: state.ui.sites.linkSiteError,
	};
};

const mapDispatchToProps = ( dispatch ) => {
	return {
		onConnect: ( url, type ) => {
			dispatch( linkSite( url, type ) );
		},
		onChange: ( url ) => {
			dispatch( updateSiteUrl( url ) );
		},
	};
};

const AddSiteContainer = connect(
	mapStateToProps,
	mapDispatchToProps,
)( AddSite );

AddSiteContainer.propTypes = {
	initialURL: PropTypes.string,
};

export default injectIntl( AddSiteContainer );
