import React from "react";
import { defineMessages, FormattedMessage, injectIntl } from "react-intl";
import PropTypes from "prop-types";
import { Alert, Link } from "@yoast/ui-library";

const messages = defineMessages( {
	contactSupportLink: {
		id: "contact.support.link",
		defaultMessage: "please contact support",
	},
	OFACInformationPageLink: {
		id: "contact.OFAC.link",
		defaultMessage: "OFAC information page",
	},
} );

/**
 * This class can render error messages in our custom style. It outputs the styled error message if its errorMessage prop is not empty.
 * Else, it renders null.
 *
 * @returns {ReactElement} The rendered ErrorMessage component.
 */
class ErrorDisplay extends React.Component {
	/**
	 * Checks the errormessage for placeholders, replaces them with desired content. Outputs an object that can be used by formatMessage().
	 *
	 * @param {string} errorMessage The error message.
	 *
	 * @returns {Object} An object with a defaultMessage and values, which can be used by FormattedMessage.
	 */
	handlePlaceholders( errorMessage ) {
		let defaultMessage = "{ errorMessage }";
		let values         = { errorMessage };

		// In the case of a [customer_support_link] placeholder, replace with an e-mail link to support. Will eventually link to Knowledge base.
		if ( errorMessage.indexOf( "[customer_support_link]" ) > -1 ) {
			errorMessage      = errorMessage.replace( "[customer_support_link]", "" );
			const contactLink = (
				<Link href="mailto:support@yoast.com" target="_blank">
					<FormattedMessage
						id={ messages.contactSupportLink.id }
						defaultMessage={ messages.contactSupportLink.defaultMessage }
					/>
				</Link>
			);
			defaultMessage    = "{ errorMessage }{ contactLink }.";
			values            = { errorMessage, contactLink };
		}

		// In the case of an [ofac_information_page] placeholder, replace with a link to the OFAC information page.
		if ( errorMessage.indexOf( "[ofac_information_page]" ) > -1 ) {
			errorMessage   = errorMessage.replace( "[ofac_information_page]", "" );
			const link     = (
				<Link href="https://yoast.com/terms-of-service/ofac/" target="_blank">
					<FormattedMessage
						id={ messages.OFACInformationPageLink.id }
						defaultMessage={ messages.OFACInformationPageLink.defaultMessage }
					/>
				</Link>
			);
			defaultMessage = "{ errorMessage }{ contactLink }.";
			values         = { errorMessage, contactLink: link };
		}

		return ( { defaultMessage, values } );
	}

	/**
	 * Renders the component.
	 *
	 * @returns {ReactElement} The rendered component.
	 */
	render() {
		if ( ! this.props.error ) {
			return null;
		}

		const errorMessage = this.props.error.message || "Oops! Something went wrong.";

		const messageFormatObject = this.handlePlaceholders( errorMessage );

		return (
			<Alert className={ this.props.className } variant={ this.props.error.type }>
				<FormattedMessage
					id="error.display"
					defaultMessage={ messageFormatObject.defaultMessage }
					values={ messageFormatObject.values }
				/>
			</Alert>
		);
	}
}

export const ErrorPropTypeShape = PropTypes.shape( {
	message: PropTypes.string.isRequired,
	type: PropTypes.oneOf( [ "warning", "error" ] ),
} );

ErrorPropTypeShape.defaultProps = {
	message: "",
	type: "error",
};

ErrorDisplay.propTypes = {
	error: ErrorPropTypeShape,
	className: PropTypes.string,
};

ErrorDisplay.defaultProps = {
	error: null,
	className: "",
};

export default injectIntl( ErrorDisplay );
