import React, { useCallback, useContext, useState } from "react";
import { SubscriptionDetailContext } from "../../../context/SubscriptionDetailContext";
import * as styles from "./styles.scss";
import { PlusIcon } from "@heroicons/react/20/solid";
import { FormattedMessage, injectIntl, intlShape } from "react-intl";
import { messages } from "../messages";
import { Button, useToggleState } from "@yoast/ui-library";
import classnames from "classnames";
import { LinkGoogleAccountModal } from "../LinkGoogleAccountModal";
import { doRequest, prepareInternalRequest } from "shared-frontend/functions/api";
import ErrorDisplay from "../../../errors/ErrorDisplay";

export const ConnectedEmailAccess = injectIntl( ( { intl } ) => {
	const { subscription, request: subscriptionRequest }                              = useContext( SubscriptionDetailContext );
	const [ linkAccountModalIsOpen, , , openLinkAccountModal, closeLinkAccountModal ] = useToggleState( false );
	const [ isUnlinking, setIsUnlinking ]                                             = useState( false );
	const [ unlinkingError, setUnlinkingError ]                                       = useState( null );
	const unlinkAccount                                                               = useCallback( async( id, email ) => {
		// Not worth making a custom modal for this.
		// eslint-disable-next-line no-alert
		if ( ! confirm( intl.formatMessage( messages.unlinkAccountConfirmation, { email } ) ) ) {
			return;
		}
		setIsUnlinking( true );
		setUnlinkingError( null );
		try {
			// eslint-disable-next-line max-len
			/** @type {import("../../../../../nestjs-server/src/domains/Licensing/UserInterface/dtos/RevokeInvitationDto").RevokeInvitationResponseDto} */
			const response = await doRequest( prepareInternalRequest(
				`v1/access/${ subscription.id }/invite/${ id }`,
				"DELETE",
			) );
			if ( ! response.success ) {
				setUnlinkingError( {
					type: "error",
					message: intl.formatMessage( messages.unlinkAccountFailed ),
				} );
				return;
			}
			subscriptionRequest.reload();
		} catch ( error ) {
			setUnlinkingError( {
				type: "error",
				message: intl.formatMessage( messages.unlinkAccountFailed ),
			} );
		} finally {
			setIsUnlinking( false );
		}
	}, [ subscription, setIsUnlinking, setUnlinkingError ] );

	if ( subscriptionRequest.isFirstLoad || ! subscription?.emailInvites?.isRelevant ) {
		return <></>;
	}

	return <section className={ styles.connectedEmailAccess }>
		<p className={ styles.title }>
			<FormattedMessage
				{ ...messages.linkAccountsTitle }
				values={ { used: subscription.emailInvites.used, limit: subscription.emailInvites.limit } }
			/>
		</p>
		<ErrorDisplay error={ unlinkingError } />
		<ul className={ styles.emailList }>
			{ subscription.emailInvites.usedSeats
				.map( emailAccess => {
					return <li
						key={ emailAccess.id }
						className={ classnames( { [ styles.pendingInvite ]: emailAccess.isPendingVerification } ) }
					>
						<div>
							<span className={ styles.emailAddress }>{ emailAccess.emailAddress }</span>
							{
								emailAccess.isPendingVerification &&
								<span className={ styles.status }>
									<FormattedMessage { ...messages.pendingVerification } />
								</span>
							}
							<Button
								className={ styles.unlinkAccountButton } variant="tertiary" size="small"
								onClick={ () => unlinkAccount( emailAccess.id, emailAccess.emailAddress ) }
								isLoading={ isUnlinking }
								disabled={ isUnlinking }
							>
								<FormattedMessage { ...messages.unlinkAccount } />
							</Button>
						</div>
					</li>;
				} ) }
			<li>
				<Button
					className={ styles.linkAccountButton } variant="tertiary" size="small"
					disabled={ subscription.emailInvites.used >= subscription.emailInvites.limit }
					onClick={ openLinkAccountModal }
				>
					<PlusIcon />
					<FormattedMessage { ...messages.linkAccount } />
				</Button>
			</li>
		</ul>
		<LinkGoogleAccountModal
			isOpen={ linkAccountModalIsOpen }
			onClose={ closeLinkAccountModal }
		/>
	</section>;
} );

ConnectedEmailAccess.propTypes = {
	intl: intlShape.isRequired,
};
