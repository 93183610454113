import React from "react";
import useRequest from "../reactHooks/useRequest";
import { createContext } from "react";
import PropTypes from "prop-types";
import isEmpty from "lodash/isEmpty";
import { noop } from "shared-frontend/functions/noop";

/**
 * @typedef {import('../../../../nestjs-server/src/controllers/entities/' +
 *     'subscription/dtos/SubscriptionDetailDto').SubscriptionDetailDto} SubsciptionDetails
 */


/** @type {{
 *  subscription: SubsciptionDetails|null,
 *  subscriptionId: string,
 *  request:{ isLoading: boolean, isFirstLoad: boolean, error: Object|null, reload: function }
 * }} */
const defaultValue = {
	subscription: null,
	subscriptionId: "",
	request: {
		isLoading: true,
		isFirstLoad: true,
		error: null,
		reload: noop,
	},
};

export const SubscriptionDetailContext = createContext( defaultValue );

/**
 * Provider component for SubscriptionDetailContext.
 * @param {Object} props - The properties object.
 * @param {string} props.subscriptionId - The ID of the subscription.
 * @param {React.ReactNode} props.children - The child components to provide context too.
 * @returns {JSX.Element} The provider component.
 */
export const SubscriptionDetailProvider = ( props ) => {
	/** @type {{data: SubsciptionDetails|null|[], isLoading: boolean, error: Object|null, reload: function}} */
	const usedRequest                    = useRequest( `Subscriptions/${ props.subscriptionId }/details` );
	const { data, ...request } = usedRequest;

	const subscription = isEmpty( data ) ? null : data;
	return (
		<SubscriptionDetailContext.Provider
			value={ {
				subscriptionId: props.subscriptionId,
				subscription,
				request,
			} }
		>
			{ props.children }
		</SubscriptionDetailContext.Provider>
	);
};

SubscriptionDetailProvider.propTypes = {
	subscriptionId: PropTypes.string.isRequired,
	children: PropTypes.node.isRequired,
};
