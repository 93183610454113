import { defineMessages } from "react-intl";

export const messages = defineMessages( {
	needsAttentionTitle: {
		id: "needs.attention.title",
		defaultMessage: "Needs attention",
	},
	subscriptionsTitle: {
		id: "subscriptions.title",
		defaultMessage: "Subscriptions",
	},
	partnerSubscriptionsTitle: {
		id: "partner.subscriptions.title",
		defaultMessage: "Partner subscriptions",
	},
	inactiveSubscriptionsTitle: {
		id: "inactive.subscriptions.title",
		defaultMessage: "Inactive subscriptions",
	},
	showSubscriptions: {
		id: "subscriptions.collapsibleToggle.show",
		defaultMessage: "Show subscriptions",
	},
	hideSubscriptions: {
		id: "subscriptions.collapsibleToggle.hide",
		defaultMessage: "Hide subscriptions",
	},
	tableHeaderSubscriptions: {
		id: "subscriptions.tableHeader.subscriptions",
		defaultMessage: "Subscriptions",
	},
	tableHeaderUsedOn: {
		id: "subscriptions.tableHeader.usedOn",
		defaultMessage: "Used on",
	},
	tableHeaderBillingType: {
		id: "subscriptions.tableHeader.billingType",
		defaultMessage: "Billing type",
	},
	tableHeaderNextBilling: {
		id: "subscriptions.tableHeader.nextBilling",
		defaultMessage: "Next billing",
	},
	tableHeaderDetails: {
		id: "subscriptions.tableHeader.details",
		defaultMessage: "Details",
	},
	tableHeaderPartner: {
		id: "subscriptions.tableHeader.partner",
		defaultMessage: "Partner",
	},
	paymentFailed: {
		id: "subscriptions.overview.paymentFailed",
		defaultMessage: "Payment failed",
	},
	seeDetails: {
		id: "subscriptions.overview.seeDetails",
		defaultMessage: "See details",
	},
	buyNewMessage: {
		id: "subscriptions.overview.buyNewMessage",
		defaultMessage: "Buy subscription",
	},
	RenewSubscriptionPlanMessage: {
		id: "subscriptions.overview.RenewSubscriptionPlanMessage",
		defaultMessage: "Renew now",
	},
	manualRenewMessage: {
		id: "subscriptions.overview.manualRenewMessage",
		defaultMessage: "Renew now",
	},
	details: {
		id: "subscriptions.overview.details",
		defaultMessage: "Details",
	},
	nextPaymentOn: {
		id: "subscriptions.overview.nextPaymentOn",
		defaultMessage: "Next billing: ",
	},
	endsDate: {
		id: "ends.date",
		defaultMessage: "Ends on { endDate }",
	},
	manage: {
		id: "subscriptions.overview.manage",
		defaultMessage: "Manage",
	},
	suspended: {
		id: "subscriptions.overview.suspended",
		defaultMessage: "Suspended",
	},
	pendingCancellation: {
		id: "subscriptions.overview.pendingCancellation",
		defaultMessage: "Active until end of term",
	},
	expiresSoon: {
		id: "subscriptions.overview.expiresSoon",
		defaultMessage: "Expires soon",
	},
	expired: {
		id: "subscriptions.overview.expired",
		defaultMessage: "Expired",
	},
	gotoProvisioner: {
		id: "subscriptions.overview.gotoProvisioner",
		defaultMessage: "{ name }",
	},
	usedSites: {
		id: "subscriptions.overview.usedSites",
		defaultMessage: "{ used }/{ limit } sites",
	},
	usedSeats: {
		id: "subscriptions.overview.usedSeats",
		defaultMessage: "{ used }/{ limit } Google accounts",
	},
} );
