import React, { useContext } from "react";
import { SubscriptionDetailContext } from "../../../context/SubscriptionDetailContext";
import { FormattedMessage } from "react-intl";
import { messages } from "../messages";
import { Button, Link, useToggleState } from "@yoast/ui-library";
import { PlusIcon } from "@heroicons/react/20/solid";
import * as styles from "./styles.scss";
import { Link as RouterLink } from "react-router-dom";
import { AddSiteModal } from "../../modal/AddSiteModal";
import AddSite from "../../AddSite";

/**
 * A list of connected sites in the subscription.
 * @returns {Element} The connected sites.
 */
export const ConnectedSites = () => {
	const { subscription, request: { isFirstLoad } }                      = useContext( SubscriptionDetailContext );
	const [ addSiteModalOpen, , , openAddSiteModal, closeAddSiteModal ] = useToggleState( false );

	if ( isFirstLoad || ! subscription ) {
		return <></>;
	}

	return <section className={ styles.connectedSites }>
		<p className={ styles.title }>
			<FormattedMessage
				{ ...messages.connectedSitesTitle }
				values={ { used: subscription.connectedSites.length, limit: subscription.limit } }
			/>
		</p>
		<ul>
			{ subscription.connectedSites?.map( site => {
				return <li key={ site.id }>
					<div>
						<span>{ site.url }</span>
						<Link
							className={ styles.manage }
							as={ RouterLink }
							to={ `/sites/${ site.id }` }
						>
							<FormattedMessage { ...messages.manageSite } />
						</Link>
					</div>
				</li>;
			} ) }
			<li>
				<Button
					className={ styles.addSiteButton }
					variant="tertiary"
					size="small"
					onClick={ openAddSiteModal }
					disabled={ subscription.connectedSites?.length >= subscription.limit }
				>
					<PlusIcon />
					<FormattedMessage { ...messages.connectSite } />
				</Button>
			</li>
		</ul>

		<AddSiteModal onClose={ closeAddSiteModal } modalOpen={ addSiteModalOpen }>
			<AddSite/>
		</AddSiteModal>
	</section>;
};
