import React, { useContext } from "react";
import { SubscriptionDetailContext } from "../../../context/SubscriptionDetailContext";
import * as styles from "./styles.scss";
import { messages } from "../messages";
import { Link } from "@yoast/ui-library";
import { FormattedMessage } from "react-intl";
import ArrowDownTrayIcon from "@heroicons/react/20/solid/ArrowDownTrayIcon";
import { ExternalLink } from "../../ExternalLink";

/**
 * A list of included plugins in the subscription
 * @returns {Element} The included plugins.
 * @constructor
 */
export const IncludedPlugins = () => {
	const { subscription, request: { isFirstLoad } } = useContext( SubscriptionDetailContext );

	if ( isFirstLoad || subscription.included.inSelf.plugins.length === 0 ) {
		return <></>;
	}

	return <section className={ styles.includedPlugins }>
		<p className={ styles.title }><FormattedMessage { ...messages.productsTitle } /></p>
		<ul className={ styles.pluginList }>
			{ subscription.included.inSelf.plugins.map( plugin => {
				return <li key={ plugin.name }>
					<div>
						<span>{ plugin.name }</span>
						{ plugin.downloadUrl &&
							<Link
								href={ plugin.downloadUrl }
								size="small"
								className={ styles.download }
							>
								<ArrowDownTrayIcon />
								<FormattedMessage { ...messages.downloadLinkText } />
							</Link>
						}
					</div>
				</li>;
			} ) }
		</ul>
		<p>
			<ExternalLink href={ "https://yoa.st/myyoast-installation" }>
				<FormattedMessage { ...messages.installationGuides } />
			</ExternalLink>
		</p>
	</section>;
};
