import React from "react";
import { useContext } from "react";
import { SubscriptionDetailContext } from "../../../context/SubscriptionDetailContext";
import * as styles from "./styles.scss";
import { messages } from "../messages";
import { FormattedMessage } from "react-intl";

/**
 * A list of included courses in the subscription.
 * @returns {Element} The included courses.
 */
export const IncludedCourses = () => {
	const { subscription, request: { isFirstLoad } } = useContext( SubscriptionDetailContext );
	if ( isFirstLoad || subscription.included.inSelf.courses.length === 0 ) {
		return <></>;
	}

	return <section className={ styles.includedCourses }>
		<p className={ styles.title }><FormattedMessage { ...messages.coursesTitle } /></p>
		<ul className={ styles.courseList }>
			{ subscription.included.inSelf.courses.map( course => {
				return <li key={ course.name }>
					<span>{ course.name }</span>
				</li>;
			} ) }
		</ul>
	</section>;
};
