import { defineMessages, injectIntl, intlShape } from "react-intl";
import React from "react";
import PropTypes from "prop-types";
import { Modal } from "@yoast/ui-library";
import { connect } from "react-redux";
import { linkSiteModalClose, linkSiteModalOpen } from "../../redux/actions/sites";

const modalAriaLabel = defineMessages( {
	id: "modal.arialabel.add",
	defaultMessage: "Add a new site",
} );

/**
 * Returns the AddSite modal.
 *
 * @param {Object} props The props required.
 * @returns { ReactElement } The AddSite modal.
 */
const AddSiteModal = ( props ) => (
	<Modal
		isOpen={ props.modalOpen }
		onClose={ props.onClose }
		aria-label={ props.intl.formatMessage( modalAriaLabel ) }
	>
		<Modal.Panel>
			<Modal.Title as="h2" size="2">Add site</Modal.Title>
			{ props.children }
		</Modal.Panel>
	</Modal>
);


AddSiteModal.propTypes = {
	intl: intlShape.isRequired,
	onClose: PropTypes.func.isRequired,
	modalOpen: PropTypes.bool,
	children: PropTypes.node.isRequired,
};

AddSiteModal.defaultProps = {
	modalOpen: false,
};


const mapStateToProps = ( state ) => {
	return {
		modalOpen: state.ui.sites.addSiteModalOpen,
	};
};

const mapDispatchToProps = ( dispatch ) => {
	return {
		onClick: () => {
			dispatch( linkSiteModalOpen() );
		},
		onClose: () => {
			dispatch( linkSiteModalClose() );
		},
	};
};

const AddSiteModalContainer = connect(
	mapStateToProps,
	mapDispatchToProps,
)( AddSiteModal );

AddSiteModalContainer.propTypes = {
	children: PropTypes.node.isRequired,
};

const AddSiteModalWithIntl = injectIntl( AddSiteModal );
export { AddSiteModalWithIntl as AddSiteModal };
export default injectIntl( AddSiteModalContainer );
