import React from "react";
import { defineMessages, FormattedMessage, injectIntl } from "react-intl";

import { getDownloadProfileUrl } from "shared-frontend/functions/api";
import { getAccessToken, getUserId } from "shared-frontend/functions/auth";
// Custom components.
import { Button, Title } from "@yoast/ui-library";
import * as styles from "../../../ProfilePageStyles.scss";


const messages = defineMessages( {
	title: {
		id: "download-account.title",
		defaultMessage: "Download personal data",
	},
	description: {
		id: "download-account.description",
		defaultMessage: "If you want to retrieve a copy of all the personal data" +
			" stored in your account, you can do so here.",
	},
	button: {
		id: "download-account.button",
		defaultMessage: "Download my data",
	},
} );

/**
 * The DeleteAccount component.
 */
class DownloadAccount extends React.Component {
	constructor( props ) {
		super( props );
		this.generateDownloadURL = this.generateDownloadURL.bind( this );
	}

	generateDownloadURL() {
		const userId = getUserId();
		return getDownloadProfileUrl( userId );
	}

	/**
	 * Renders the component.
	 *
	 * @returns {ReactElement} The rendered component.
	 */
	render() {
		return (
			<form action={ this.generateDownloadURL() } className={ styles.pageSection }>
				<div className={ styles.sectionTitle }>
					<Title as="h2" size="4">
						<FormattedMessage id={ messages.title.id } defaultMessage={ messages.title.defaultMessage } />
					</Title>
				</div>
				<div className={ styles.sectionContent }>
					<p>
						<FormattedMessage
							id={ messages.description.id }
							defaultMessage={ messages.description.defaultMessage }
						/>
					</p>
					{ <Button type="submit" variant="secondary">
						<FormattedMessage id={ messages.button.id } defaultMessage={ messages.button.defaultMessage } />
					</Button> }
					<input type="hidden" name="access_token" value={ getAccessToken() } />
				</div>
			</form>
		);
	}
}

export default injectIntl( DownloadAccount );
