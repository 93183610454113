import PropTypes from "prop-types";
import React from "react";
import { FormattedMessage, injectIntl, intlShape } from "react-intl";
import { Table, Title } from "@yoast/ui-library";
import * as styles from "./styles.scss";
import { messages } from "./messages";
import SubscriptionRow from "./SubscriptionRow";
import CollapsibleSubscriptionsRow from "./CollapsibleSubscriptionsRows";

/**
 * Returns the title text for the SubscriptionList component.
 *
 * @param {object} props The props to use.
 * @returns {Element} The title text.
 */
function getTitle( props ) {
	if ( props.needsAttention ) {
		return <FormattedMessage { ...messages.needsAttentionTitle } />;
	}

	if ( props.isProvisioned ) {
		return <FormattedMessage { ...messages.partnerSubscriptionsTitle } />;
	}

	if ( props.isInactive ) {
		return <FormattedMessage { ...messages.inactiveSubscriptionsTitle } />;
	}

	return <FormattedMessage { ...messages.subscriptionsTitle } />;
}

function getTableRow( subscriptions, props ) {
	if ( subscriptions.length === 1 ) {
		return <SubscriptionRow
			key={ subscriptions[ 0 ].id }
			subscriptionsArray={ subscriptions }
			provisionerData={ props.provisionerData }
			onManage={ props.onManage }
			isProvisioned={ props.isProvisioned }
			needsAttention={ props.needsAttention }
			isInactive={ props.isInactive }
			showDetailsModal={ props.showDetailsModal }
		/>;
	}

	if ( subscriptions.length > 1 ) {
		return <CollapsibleSubscriptionsRow
			key={ subscriptions[ 0 ].id }
			subscriptionsArray={ subscriptions }
			provisionerData={ props.provisionerData }
			onManage={ props.onManage }
			isProvisioned={ props.isProvisioned }
			needsAttention={ props.needsAttention }
			isInactive={ props.isInactive }
			showDetailsModal={ props.showDetailsModal }
		/>;
	}

	return null;
}

/**
 *
 * @param {Object} props The props to use
 *
 * @returns {ReactElement} The rendered component.
 */
const SubscriptionsTable = ( props ) => {
	// When there are multiple subscriptions for the same product, we want to make those subscriptions collapsible.
	const subscriptionsWithCollapsiblesFirst = Object.values( props.subscriptions ).sort( ( a, b ) => {
		return b.length - a.length;
	} );

	const isPartnerSubscriptionsTable = props.isProvisioned;

	/**
	 * Get the default headers for the table.
	 *
	 * @returns {Element} The default headers.
	 */
	function getDefaultHeaders() {
		return <Table.Head>
			<Table.Row>
				<Table.Header>
					{ props.intl.formatMessage( messages.tableHeaderSubscriptions ) }
				</Table.Header>
				<Table.Header className={styles.tableUsage}>
					{ props.intl.formatMessage( messages.tableHeaderUsedOn ) }
				</Table.Header>
				<Table.Header className={styles.tableRenewal}>
					{ props.intl.formatMessage( messages.tableHeaderBillingType ) }
				</Table.Header>
				<Table.Header className={styles.tableBilling}>
					{ props.intl.formatMessage( messages.tableHeaderNextBilling ) }
				</Table.Header>
				<Table.Header>
					<div />
				</Table.Header>
			</Table.Row>
		</Table.Head>;
	}

	function getPartnerSubscriptionsHeaders() {
		return <Table.Head>
			<Table.Row>
				<Table.Header>
					{ props.intl.formatMessage( messages.tableHeaderSubscriptions ) }
				</Table.Header>
				<Table.Header>
					{ props.intl.formatMessage( messages.tableHeaderPartner ) }
				</Table.Header>
			</Table.Row>
		</Table.Head>;
	}

	/**
	 * Renders the component.
	 *
	 * @returns {JSX.Element} Component.
	 * */
	return (
		<div>
			<Title as="h2" size="2">
				{ getTitle( props ) }
			</Title>
			<br />
			<Table>
				<React.Fragment key="0.1">
					{ isPartnerSubscriptionsTable ? getPartnerSubscriptionsHeaders() : getDefaultHeaders() }
					<Table.Body>
						{ subscriptionsWithCollapsiblesFirst.map( subscriptions => getTableRow( subscriptions, props ) ) }
					</Table.Body>
				</React.Fragment>
			</Table>
			<br />
		</div>
	);
};

export const SubscriptionProps = PropTypes.oneOfType( [
	PropTypes.arrayOf(
		PropTypes.shape(
			{
				id: PropTypes.string.isRequired,
				icon: PropTypes.string.isRequired,
				name: PropTypes.string.isRequired,
				used: PropTypes.number.isRequired,
				limit: PropTypes.number.isRequired,
				hasNextPayment: PropTypes.bool.isRequired,
				nextPayment: PropTypes.instanceOf( Date ).isRequired,
				hasEndDate: PropTypes.bool.isRequired,
				endDate: PropTypes.instanceOf( Date ).isRequired,
				billingAmount: PropTypes.number.isRequired,
				billingCurrency: PropTypes.string.isRequired,
				status: PropTypes.string.isRequired,
				renewalSecret: PropTypes.string.isRequired,
				emailAccessList: PropTypes.arrayOf( PropTypes.shape( {
					emailAddress: PropTypes.string.isRequired,
					inviteStatus: PropTypes.string.isRequired,
				} ) ).isRequired,
			},
		),
	),
	PropTypes.object,
] );

SubscriptionsTable.propTypes = {
	subscriptions: SubscriptionProps,
	provisionerData: PropTypes.object,
	onManage: PropTypes.func.isRequired,
	intl: intlShape.isRequired,
	needsAttention: PropTypes.bool,
	isProvisioned: PropTypes.bool,
	isInactive: PropTypes.bool,
	showDetailsModal: PropTypes.func,
};

SubscriptionsTable.defaultProps = {
	subscriptions: [],
	provisionerData: {},
	needsAttention: false,
	isProvisioned: false,
	isInactive: false,
	showDetailsModal: null,
};

export default injectIntl( SubscriptionsTable );
