import React from "react";
import { PageSection } from "../../PageSection";

import * as styles from "./styles.scss";

export const SubscriptionSection = ( props ) => {
	const getLines = ( lines ) => {
		return lines.map( ( line, i ) => {
			if ( ! line ) {
				return;
			}
			return (
				<div className={ styles.detailsLine } key={ `section-${ i }` }>
					<label className={ styles.lineTitle }>
						{ line.title }
					</label>
					{ line.value &&
						<div className={ styles.lineValue }>
							{ line.value }
						</div>
					}
					{ line.action &&
						<div className={ styles.lineAction }>
							{ line.action }
						</div>
					}
				</div>
			);
		} );
	};

	return (
		<PageSection title={ props.title }>
			<div className={ styles.subscriptionSection }>
				{ getLines( props.lines ) }
			</div>
		</PageSection>
	);
};
