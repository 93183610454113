import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
import React from "react";
import PropTypes from "prop-types";
import * as styles from "./styles.scss";
import classNames from "classnames";

/**
 * A collapsable section that expands when clicked.
 * @param {Object} props The component props.
 * @param {ReactNode} props.children The content of the collapsable section.
 * @param {boolean} [props.initialOpenState] The initial open state of the collapsable section.
 * @param {string} [className] The class name to apply to the content.
 * @returns {Element} The collapsable section.
 */
const Details = ( { initialOpenState = false, children, className = "" } ) => {
	return <details open={ initialOpenState } className={ classNames( styles.details, className ) }>
		{ children }
	</details>;
};

Details.propTypes = {
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
	initialOpenState: PropTypes.bool,
};

/**
 * The summary of a collapsible section, also shown in the closed state.
 * @param {object} props The component props
 * @param {ReactNode} children The summary to show This can be phrasing or heading content.
 * @param {string} [className] The class name to apply to the content.
 * @returns {Element} The summary of the Details component.
 */
const Summary = ( { children, className = "" } ) => {
	return <summary className={ classNames( styles.summary, className ) }>
		{ children }
		<ChevronUpIcon className={ styles.showWhenOpen } />
		<ChevronDownIcon className={ styles.showWhenClosed } />
	</summary>;
};

Summary.propTypes = {
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
};

/**
 * The content of a collapsible section, only shown in the opened state.
 * @param {object} props The component props
 * @param {ReactNode} children The contents to show.
 * @param {string} [className] The class name to apply to the content.
 * @returns {Element} The contents of the Details component.
 */
const Content = ( { children, className = "" } ) => {
	return <div className={ classNames( styles.content, className ) }>
		{ children }
	</div>;
};

Content.propTypes = {
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
};


Details.Summary = Summary;
Details.Content = Content;
export { Details };

