import "whatwg-fetch";

import { doRequest, getMyYoastHost, handleJSONResponse, prepareInternalRequest } from "../../functions/api";
import { getUserId } from "../../functions/auth";

const host = getMyYoastHost();

/*
 * Action types
 */

export const GET_ALL_SUBSCRIPTIONS_REQUEST = "GET_ALL_SUBSCRIPTIONS_REQUEST";
export const GET_ALL_SUBSCRIPTIONS_SUCCESS = "GET_ALL_SUBSCRIPTIONS_SUCCESS";
export const GET_ALL_SUBSCRIPTIONS_FAILURE = "GET_ALL_SUBSCRIPTIONS_FAILURE";

export const CANCEL_SUBSCRIPTION_REQUEST = "CANCEL_SUBSCRIPTION_REQUEST";
export const CANCEL_SUBSCRIPTION_SUCCESS = "CANCEL_SUBSCRIPTION_SUCCESS";
export const CANCEL_SUBSCRIPTION_FAILURE = "CANCEL_SUBSCRIPTION_FAILURE";

export const FORCE_CANCEL_SUBSCRIPTION_REQUEST = "FORCE_CANCEL_SUBSCRIPTION_REQUEST";
export const FORCE_CANCEL_SUBSCRIPTION_SUCCESS = "FORCE_CANCEL_SUBSCRIPTION_SUCCESS";
export const FORCE_CANCEL_SUBSCRIPTION_FAILURE = "FORCE_CANCEL_SUBSCRIPTION_FAILURE";

export const RECALCULATE_SUBSCRIPTION_COUNT_SEND    = "RECALCULATE_SUBSCRIPTION_COUNT_SEND";
export const RECALCULATE_SUBSCRIPTION_COUNT_SUCCESS = "RECALCULATE_SUBSCRIPTION_COUNT_SUCCESS";
export const RECALCULATE_SUBSCRIPTION_COUNT_FAILURE = "RECALCULATE_SUBSCRIPTION_COUNT_FAILURE";

export const SET_EXPIRY_DATE_REQUEST = "SET_EXPIRY_DATE_REQUEST";
export const SET_EXPIRY_DATE_SUCCESS = "SET_EXPIRY_DATE_SUCCESS";
export const SET_EXPIRY_DATE_FAILURE = "SET_EXPIRY_DATE_FAILURE";

/*
 * Action creators
 */

/**
 * An action creator for the get all subscriptions request action.
 *
 * @returns {Object} A get all subscriptions action.
 */
export function getAllSubscriptionsRequest() {
	return {
		type: GET_ALL_SUBSCRIPTIONS_REQUEST,
	};
}

/**
 * An action creator for the get all subscriptions success action.
 * @param {Object} subscriptions The subscriptions json object
 * @returns {Object} A get all subscriptions success action.
 */
export function getAllSubscriptionsSuccess( subscriptions ) {
	return {
		type: GET_ALL_SUBSCRIPTIONS_SUCCESS,
		subscriptions: subscriptions,
	};
}

/**
 * An action creator for the get all subscriptions failure action.
 *
 * @param {Object} error The error that was thrown.
 *
 * @returns {Object} A get all subscriptions failure action.
 */
export function getAllSubscriptionsFailure( error ) {
	return {
		type: GET_ALL_SUBSCRIPTIONS_FAILURE,
		error: error,
	};
}

/**
 * An action creator for the get all subscriptions action.
 *
 * @returns {Object} A get all subscriptions action.
 */
export function getAllSubscriptions() {
	return ( dispatch ) => {
		dispatch( getAllSubscriptionsRequest() );

		const userId = getUserId();

		if ( ! userId ) {
			return [];
		}

		const request = prepareInternalRequest(
			`Customers/${ userId }/subscriptions/`,
			"GET",
			{},
			{
				queryParams: {
					filter: {
						include: [
							"orders",
							"product",
							"product.productGroups",
							"productSwitches.isRevertedBy",
							"emailAccessList",
						],
					},
				},
			} );

		return doRequest( request )
			.then( json => dispatch( getAllSubscriptionsSuccess( json ) ) )
			.catch( error => dispatch( getAllSubscriptionsFailure( error ) ) );
	};
}

/**
 * An action creator for the cancel subscription request action.
 *
 * @returns {Object} A cancel subscription action.
 */
export function cancelSubscriptionRequest() {
	return {
		type: CANCEL_SUBSCRIPTION_REQUEST,
	};
}

/**
 * An action creator for the cancel subscription success action.
 *
 * @param {string} subscriptionId The subscription that was cancelled.
 *
 * @returns {Object} A cancel subscription success action.
 */
export function cancelSubscriptionSuccess( subscriptionId ) {
	return {
		type: CANCEL_SUBSCRIPTION_SUCCESS,
		subscriptionId,
	};
}

/**
 * An action creator for the cancel subscription failure action.
 *
 * @param {Object} error The error object that was returned.
 *
 * @returns {Object} A cancel subscription failure action.
 */
export function cancelSubscriptionFailure( error ) {
	return {
		type: CANCEL_SUBSCRIPTION_FAILURE,
		error: error,
	};
}

/**
 * An action creator for the cancel subscription action.
 *
 * @param {string} subscriptionId The id of the subscription to cancel.
 * @param {number} amount The amount with which to 'cancel' the subscription.
 *
 * @returns {Object} A get all subscription action.
 */
export function cancelSubscription( subscriptionId, amount ) {
	return ( dispatch ) => {
		dispatch( cancelSubscriptionRequest() );

		const userId = getUserId();

		const request = prepareInternalRequest( `Customers/${ userId }/subscriptions/${ subscriptionId }/cancel`, "POST", { amount: amount } );

		return doRequest( request )
			.then( () => dispatch( cancelSubscriptionSuccess( subscriptionId ) ) )
			.then( () => dispatch( getAllSubscriptions() ) )
			.catch( error => {
				dispatch( cancelSubscriptionFailure( error ) );
			} );
	};
}

/**
 * An action creator for the force cancel force subscription request action.
 *
 * @param {string} subscriptionId The id of the subscription.
 *
 * @returns {Object} A force cancel subscription action.
 */
export function forceCancelSubscriptionRequest( subscriptionId ) {
	return {
		type: FORCE_CANCEL_SUBSCRIPTION_REQUEST,
		subscriptionId,
	};
}

/**
 * An action creator for the force cancel subscription success action.
 *
 * @param {string} subscriptionId The subscription that was cancelled.
 *
 * @returns {Object} A force cancel subscription success action.
 */
export function forceCancelSubscriptionSuccess( subscriptionId ) {
	return {
		type: FORCE_CANCEL_SUBSCRIPTION_SUCCESS,
		subscriptionId,
	};
}

/**
 * An action creator for the force cancel force subscription failure action.
 *
 * @param {Object} error The error object that was returned.
 * @param {string} subscriptionId The id of the subscription.
 *
 * @returns {Object} A force cancel subscription failure action.
 */
export function forceCancelSubscriptionFailure( error, subscriptionId ) {
	return {
		type: FORCE_CANCEL_SUBSCRIPTION_FAILURE,
		subscriptionId,
		error: error,
	};
}

/**
 * An action creator for the force cancel subscription action.
 *
 * @param {string} subscriptionId The id of the subscription to cancel.
 *
 * @returns {Object} A force cancel subscription action.
 */
export function forceCancelSubscription( subscriptionId ) {
	return ( dispatch ) => {
		dispatch( forceCancelSubscriptionRequest( subscriptionId ) );

		const request = prepareInternalRequest( `subscriptions/${ subscriptionId }/force-cancel`, "POST" );

		return doRequest( request )
			.then( () => dispatch( forceCancelSubscriptionSuccess( subscriptionId ) ) )
			.catch( error => {
				dispatch( forceCancelSubscriptionFailure( error, subscriptionId ) );
			} );
	};
}

/**
 * An action creator that updates the reducer that the request will be send.
 *
 * @returns {Object} The action.
 */
const recalculateSubscriptionCountSend = () => {
	return {
		type: RECALCULATE_SUBSCRIPTION_COUNT_SEND,
	};
};

/**
 * An action creator that updates the reducer that the request succeeded.
 *
 * @param {Object} subscription The recalculated subscription.
 *
 * @returns {Object} The action.
 */
const recalculateSubscriptionCountSuccess = subscription => {
	return {
		type: RECALCULATE_SUBSCRIPTION_COUNT_SUCCESS,
		subscription,
	};
};


/**
 * An action creator that updates the reducer that the request failed.
 *
 * @returns {Object} The action
 */
const recalculateSubscriptionCountFailure = () => {
	return {
		type: RECALCULATE_SUBSCRIPTION_COUNT_FAILURE,
	};
};

/**
 * Recalculates the `used` count for a subscription.
 *
 * @param {string} subscriptionId The Id of the subscription..
 *
 * @returns {void} dispatches an action to the store.
 */
export const recalculateSubscriptionCount = subscriptionId => async( dispatch, getState ) => {
	const accessToken = getState().user.accessToken;
	const url         = `${ host }/api/Subscriptions/${ subscriptionId }/recalculateSubscriptionCount?access_token=${ accessToken }`;

	dispatch( recalculateSubscriptionCountSend() );
	try {
		const rawResponse = await fetch( url, { method: "POST" } );
		const response    = await handleJSONResponse( rawResponse );

		dispatch( recalculateSubscriptionCountSuccess( response ) );
	} catch ( error ) {
		dispatch( recalculateSubscriptionCountFailure() );
	}
};

/**
 * An action creator for the set expiry date request action.
 *
 * @param {string} subscriptionId The id of the subscription to set the expiry date for.
 * @param {Date} expiryDate The new expiry date.
 *
 * @returns {Object} A set expiry date request action.
 */
export function setExpiryDateRequest( subscriptionId, expiryDate ) {
	return {
		type: SET_EXPIRY_DATE_REQUEST,
		subscriptionId,
		expiryDate,
	};
}

/**
 * An action creator for the set expiry date success action.
 *
 * @param {string} subscriptionId The id of the subscription to set the expiry date for.
 * @param {Date} expiryDate The new expiry date.
 *
 * @returns {Object} A set expiry date success action.
 */
export function setExpiryDateSuccess( subscriptionId, expiryDate ) {
	return {
		type: SET_EXPIRY_DATE_SUCCESS,
		subscriptionId,
		expiryDate,
	};
}

/**
 * An action creator for the set expiry date failure action.
 *
 * @param {string} subscriptionId The id of the subscription to set the expiry date for.
 * @param {Date} expiryDate The new expiry date.
 * @param {Object} error The error object that was returned.
 *
 * @returns {Object} A set expiry date failure action.
 */
export function setExpiryDateFailure( subscriptionId, expiryDate, error ) {
	return {
		type: SET_EXPIRY_DATE_FAILURE,
		subscriptionId,
		expiryDate,
		error,
	};
}

/**
 * An action creator for setting the expiry date of a subscription.
 *
 * @param {string} subscriptionId The id of the subscription to set the expiry date for.
 * @param {Date} expiryDate The new expiry date.
 *
 * @returns {function} The action.
 */
export const setExpiryDate = ( subscriptionId, expiryDate ) => {
	return async function( dispatch ) {
		dispatch( setExpiryDateRequest( subscriptionId, expiryDate ) );
		const request = prepareInternalRequest( "Subscriptions/setExpiryDate", "POST", { subscriptionId, expiryDate } );
		try {
			await doRequest( request );
			dispatch( setExpiryDateSuccess( subscriptionId, expiryDate ) );
		} catch ( exception ) {
			dispatch( setExpiryDateFailure( subscriptionId, expiryDate, exception ) );
		}
	};
};
